<template>
  <div id="app">
    <!--------------------------------------------------------------------------------------------------------------------------------->

    <!-- disclaimer -->
    <div class="disclaimer" v-if="!menores">
      <div class="translucid_bg">
        <div class="disclaimer_wrapper">
          <div class="disclaimer_block">
            <div class="disclaimer_content">
              <div class="disclaimer_text">
                <img src="@/assets/images/logo.png" class="disclaimer_logo">
                <h2>Este portal contiene material sensible exclusivamente para adultos, y por tanto al hacer clic en "ENTRAR", admite:</h2>
                <ul>
                  <li>Usted debe ser adulto, entendiéndose como tal ser mayor de 18 años (o la edad legal en su lugar de residencia).</li>
                  <li>Usted está de acuerdo en ver material sexualmente explícito para su uso personal en su ordenador privado y no utilizar el material de Premios Bonobo con fines comerciales.</li>
                  <li>Usted se deberá hacer cargo de que el consumo de material e imágenes de contenido para adultos no esté prohibido ni infrinja ninguna ley en la comunidad donde usted reside, ni en su proveedor de servicios, ni desde el local desde donde accede.</li>
                  <li>Usted no exhibirá este material a menores o a cualquier otra persona que pueda resultar ofendida.
                      Haga clic en ENTRAR si está de acuerdo y cumple las condiciones anteriores, o haga clic en SALIR si no quiere entrar o no cumple las condiciones anteriores</li>
                </ul>
                <div class="disclaimer_cta">
                  <div class="enter_btn" @click="aceptoMenores">Entrar</div>
                </div>
                <div class="disclaimer_cta">
                  <a href="https://observatoriodelainfancia.mdsocialesa2030.gob.es/" target="_self" class="exit_btn">Salir</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /disclaimer -->

    <div class="init_message" v-if="!cookies">
      <div class="message_content">La verdad que sentimos darte la paliza con la Cookies, a nosotros nos gustan más las bananas. Nosotros No comercializamos tus datos, pero nos obligan a darte el coñazo y anunciarte todo esto, tienes que aprobar con un click que aceptas el uso de las cookies que tenemos en nuestra web para una mejor navegación y seguridad para tí. <router-link to="/legal" title="cookies" target="_blank">Más información</router-link>
        <div class="close_btn" @click="aceptoCookies">Acepto</div>
      </div>
    </div>

    <ModalImagen :srcImg="this.ModalImage.src" :visible="this.ModalImage.visible" :pos="this.ModalImage.pos" :array="this.ModalImage.array" @cerrar="()=>{ this.ModalImage.visible = false; }"/>


    <!--------------------------------------------------------------------------------------------------------------------------------->


    <!--<div v-if="loading" class="main_loader" style="position: relative; top: 0; right: 0; height: 100vh; background: white; z-index: 99">CARGANDO</div>-->
    <div class="wrapper">
      <main class="main_content">
        <!-- <transition name="fade"> -->
          <div v-if="loading" class="curtain">
            <div class="monkeys"></div>
          </div>
        <!-- </transition> -->
        <!--<transition name="fade">-->
          <router-view :key="$route.fullPath"/>
        <!--</transition>-->
        <pie key="pie"/>
        <div class="menu_wrapper" :style="{display: menuAbierto ? 'block' : ''}">
          <div class="menu_container">
            <div class="inner">
              <nav>
                <ul>
                  <li class="big_btn">
                    <router-link to="/" class="home_link" exact><div class="tv_button"><span></span></div>Portada</router-link>
                  </li>
                  <li class="big_btn">
                    <router-link to="/seccion/1" class="contest_section_link"><div class="tv_button"><span></span></div>A concurso</router-link>
                  </li>
                  <li class="big_btn">
                    <router-link to="/seccion/8" class="special_section_link"><div class="tv_button"><span></span></div>Sección Especial</router-link>
                  </li>
                  <li class="big_btn">
                    <router-link to="/seccion/9" class="more_stuff_section_link"><div class="tv_button"><span></span></div>Más material</router-link>
                  </li>
                  <li class="big_btn">
                    <router-link :to="'/old/2019'" class="past_editions_section_link"><div class="tv_button"><span></span></div>Ediciones pasadas</router-link>
                  </li>
                  <li class="big_btn">
                    <router-link to="/cinema_bonobo" class="past_editions_section_link"><div class="tv_button"><span></span></div>Cinema Bonobo</router-link>
                  </li>
                  <li class="sep">
                    <div></div>
                  </li>
                  <li  class="small_btn">
                    <router-link to="/participa"><div class="tv_button"><span></span></div>Inscribirse</router-link>
                  </li>
                  <li  class="small_btn">
                    <a href="https://shopohs.es/" target="_blank"><div class="tv_button"><span></span></div>Tienda</a>
                  </li>
                  <li  class="small_btn">
                    <router-link to="/noticias"><div class="tv_button"><span></span></div>Noticias</router-link>
                  </li>
                  <li  class="small_btn">
                    <router-link to="/contacto"><div class="tv_button"><span></span></div>Contacto</router-link>
                  </li>
                  <li  class="small_btn">
                    <a href="javascript:void(0)" v-if="logued" @click="logout"><div class="tv_button"><span></span></div>Logout</a>
                    <router-link v-else :to="`/login`"><div class="tv_button"><span></span></div>Login / Registro</router-link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Jeader />
      </main>
    </div>
  </div>
</template>

<script>
import Jeader from '@/components/Jeader.vue'
import Pie from '@/components/Pie.vue'
import axios from 'axios'
import ModalImagen from '@/components/ModalImagen.vue'

export default {
  name: "app",
  components: {
    Jeader,
    Pie,
    ModalImagen
  },
  data: function(){
    return{
      loading: true,
      cookies: false,
      menores: false,
      menuAbierto: false,
      logued: null,
      ModalImage: {
        src:"",
        visible:false,
        array: [],
        pos: 0
      }
    }
  },
  computed: {

  },
  methods:{
    aceptoCookies: function(){
      localStorage.setItem('cookies', true);
      this.cookies = true;
    },
    aceptoMenores: function(){
      localStorage.setItem('menores', true);
      this.menores = true;
    },
    checkBoolean: function(elemento){
      if(elemento == 'true')
        return true;
      else if(elemento == 'false')
        return false;
    },
    logout: function(){
      axios
        .post(process.env.VUE_APP_URL+'logout')
        .finally(() => {
          localStorage.removeItem('user');
          window.location.reload();
        });
    },showModalImage: function(src, array=[], pos=-1){
      this.ModalImage.src =src;
      this.ModalImage.array =array;
      this.ModalImage.pos =pos;
      this.ModalImage.visible =true;
    },
    scrollFunc(){
        if(window.scrollY < 1){
            document.querySelector('.header').classList.remove('header_change');
        }
        else{
            document.querySelector('.header').classList.add('header_change');
        }
    }
  },
  created: function(){
    this.cookies = this.checkBoolean(localStorage.getItem('cookies'));
    this.menores = this.checkBoolean(localStorage.getItem('menores'));
    this.logued = localStorage.getItem('user');
  },
  mounted: function(){
    window.addEventListener('scroll', this.scrollFunc);
    this.scrollFunc();
  },
  destroyed () {
      window.removeEventListener('scroll', this.scrollFunc);
  },
  watch:{
    $route (to, from){
      if(this.menuAbierto)
        this.menuAbierto = false;
      this.logued = localStorage.getItem('user');
      this.loading = true;
    }
  }
}
</script>

<style>
  /*viejas mierdas con transition
  .fade-leave-active {
    transition: all 0.1s ease-in-out;
  }
  .fade-enter-active {
    transition: all 1.5s ease-in-out;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }*/

  /**Nuevas mierdas con el loader */
  .fade-enter-active, .fade-leave-active {
    transition: opacity .15s
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0
  }
</style>