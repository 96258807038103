<template>
    <section class="partners_section palm_general">
        <div class="content_section">
        <div class="inner">
            <header class="content_header">
              <h2><span>Patrocinan</span></h2>
            </header>
            <article class="content_category partners_list">
              <div class="grid grid_group grid_pad flex_center">
                  <div class="grid grid_group grid_pad flex_center">
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="http://www.apeoga.es/" target="_blank" title="Apeoga">
                              <img src="@/assets/images/apeoga_logo.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="https://www.androgenital.com/" target="_blank" title="Androgenital">
                              <img src="@/assets/images/androgenital_logo.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="http://www.sgae.es/" target="_blank" title="Sgae">
                              <img src="@/assets/images/sgae_logo.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="https://artisticmetropol.es" target="_blank" title="Artistic Metropol">
                              <img src="@/assets/images/artistic_metropol_logo.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="https://xcanal.tv/" target="_blank" title="Xcanal TV">
                              <img src="@/assets/images/logos_xcanal.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="https://proyectogransimio.org/" target="_blank" title="Proyecto Gran Simio">
                              <img src="@/assets/images/gran_simio_logo.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="https://www.facebook.com/Jacobykolsky/" target="_blank" title="Jacob & Kolsky Artes y Producciones">
                              <img src="@/assets/images/jakob_kolsky_logo.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="https://sexshop.thagson.com/" target="_blank" title="Thagson">
                              <img src="@/assets/images/thagson_logo.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="" class="prev_dev" target="_blank" title="mediatex">
                              <img src="@/assets/images/mediatex_logo.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="https://www.nomevaciles.com/" target="_blank" title="nomevaciles.com">
                              <img src="@/assets/images/nomevaciles_logo.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="https://dos40.es/" target="_blank" title="Dos40">
                              <img src="@/assets/images/logo_dos_cuarenta.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="https://vivex.tv/" target="_blank" title="vivex">
                              <img src="@/assets/images/vivex_logo.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="http://www.milogiraldo.com/" target="_blank" title="Milo Giraldo">
                              <img src="@/assets/images/milo_giraldo_logo.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="https://shopohs.es/" target="_blank" title="ShopohS">
                              <img src="@/assets/images/shopohs_logo.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="https://renye.es/" target="_blank" title="Renye">
                              <img src="@/assets/images/renye_logo.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="https://www.facebook.com/orangegutan.pt/" target="_blank" title="Orangegutan">
                              <img src="@/assets/images/orangegutan_logo.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="https://salaequis.es//" target="_blank" title="Sala Equis">
                              <img src="@/assets/images/sala_equis_logo.png">
                            </a>
                        </div>
                      </div>
                      <div class="grid_col">
                        <div class="partner_item">
                            <a href="https://nomadas.tv" target="_blank" title="nomadas.tv">
                              <img src="@/assets/images/nomadastv_logo.png">
                            </a>
                        </div>
                      </div>
                  </div>
              </div>
            </article>
        </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'patrocinadores'
}
</script>