<template>
    <header class="header clear header_change">
      <div class="inner">
        <div class="header_content">
          <div class="header_block left">
              <router-link to="/" class="logo ">
                <img src="@/assets/images/logo_white.png" alt="" class="logo_01">
                <img src="@/assets/images/logo.png" alt="" class="logo_02">
              </router-link>
              <h2 class="logo_claim">
                <span>World Mobile Uncensored Media Festival.</span>
                <span>Edición 2024/2025</span>
              </h2>
          </div>
          <div class="header_block right">
            <router-link to="/participa" class="btn small white">¡Inscríbete y Participa!</router-link>
          </div>
        </div>
        <a href="javascript:void(0)" class="menu_trigger" :class="{close_btn: $parent.menuAbierto}" @click="$parent.menuAbierto = !$parent.menuAbierto">
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
  </header>
</template>

<script>
export default {
    name: "jeader",
}
</script>