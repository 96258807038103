<template>
  <div id="modal_bannerbg" v-if="show">
    <div id="modal_banner">
      <button class="close_btn" @click="closeModalBan">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
          />
        </svg>
      </button>
      <div class="modal_content">
        <div class="modal_content_img">
          <img
            src="@/assets/images/libro.jpg"
            alt="Libro Blanco del cine para adultos vs. Pornografia buitre"
          />
        </div>
        <div class="modal_actions">
          <a
            href="https://shopohs.es/productos/libro_blanco_cine_adultos"
            target="_new"
            >Comprar ahora</a
          >
          <button
            @click="closeModalBan"
            style="font-size: 10px; text-decoration: underline"
          >
            Carrar ventana
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "BannerLibro",
    data: function() {
        return {
            show: true
        }
    },
    mounted() {
        if(!this.bannerHomeTimeVisible()){
            this.show = false;
            return;
        }
        let modal = document.getElementById("modal_banner");
        let modalbg = document.getElementById("modal_bannerbg");
        setTimeout(() => {
            modalbg.style.opacity = 1;
            modal.style.opacity = 1;
        }, 1000);
    },
    methods: {
        closeModalBan: function () {
            let modal = document.getElementById("modal_banner");
            let modalbg = document.getElementById("modal_bannerbg");
            modal.style.opacity = 0;
            modalbg.style.opacity = 0;
            modal.addEventListener("transitionend", () => {
                modal.style.display = "none";
                modalbg.style.display = "none";
            });
        },
        bannerHomeTimeVisible(){
            let now = parseInt(new Date().getTime() / 1000);
            const timePlus = 3600 * 24;
            const bannerHomeTime = localStorage.getItem('bannerHomeTime');
            if(!bannerHomeTime || (now > (parseInt(bannerHomeTime) + timePlus))){
                localStorage.setItem('bannerHomeTime', now);
                return true;
            }
            return false;
        }
    },
};
</script>

<style>
#modal_bannerbg {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.35s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
#modal_banner {
  visibility: visible;
  opacity: 0;
  margin: 20px 30px;
  max-width: 580px;
  height: auto;
  background: white;
  position: relative;
  overflow-y: hidden;
  border-radius: 7px;
  transition: opacity 0.35s ease;
}
#modal_banner button {
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}
#modal_banner button.close_btn {
  position: absolute;
  right: 10px;
  top: 10px;
}
#modal_banner button.close_btn svg {
  fill: #919191;
  width: 18px;
  height: 18px;
}
.modal_content_img img {
  display: block;
  min-width: 100%;
  width: 100%;
}
.modal_content {
  /*background-color: #fff;*/
  overflow-y: auto;
}
.modal_content .modal_actions {
  padding: 15px 20px;
  text-align: center;
}
.modal_content .modal_actions a {
  background-color: #aaeec4;
  color: #333;
  display: block;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 10px 15px;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
}
.modal_content .modal_actions a:hover {
  background-color: #1fe42f;
}
</style>
