<template>
    <div class="grid__motor grid__motor_col_5">
        <router-link :to="`/${categoria.extra ? 'extras' : 'obras'}/${obr.id}`"  v-for="obr in obras" :key="obr.id" class="work_link">
            <div class="work_thumb_text">
                <div v-if="!categoria.extra && !categoria.professional" class="vote_stats">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z"></path></svg>{{obr.votes}}
                </div>
                <h4 class="work_title">
                    <div class="hover_icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg></div>
                    {{obr.title}}
                </h4>
                <h5 v-if="!categoria.extra" class="work_author">
                    {{obr.participant.name+" "+obr.participant.lastname}}
                </h5>
            </div>
            <div class="work_img" :style="{'background-image': 'url('+obr.img+')'}">
                <b v-if="obr.voted" class="voted_icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z"></path></svg></b>
            </div>
        </router-link>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "obra",
    props:{
        categoria: Object,
        old: String,
        works: Array
    },
    data: function(){
        return{
            obras: []
        }
    },
    created: function(){
        if(this.works){
            this.works = this.works.sort(() => Math.random());
            this.obras = this.works.slice(0, 10);
        }
        /*axios
            .get(process.env.VUE_APP_URL + (!this.categoria.extra ? 'works' : 'extras'), {
                params:{
                    category: this.categoria.id ? this.categoria.id : 1,
                    edition: this.old ? this.old : localStorage.getItem('edition'),
                    limit: 5,
                    'orderBy[column]': 'actived_at',
                    'orderBy[order]': 'desc',
                    user: localStorage.getItem('user'),
                    active: 1
                }
            })
            .then(response => {
                let rs = response.data;
                if(rs.state == 'OK'){
                    this.obras = rs.data;
                }
                else
                    console.log('No se recibio bien')
            })
            .catch(error => {
                console.log(error);
            });*/
    }
}
</script>